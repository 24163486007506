import React from 'react';
import './index.css';
import ReactDOM from 'react-dom/client'; // Import from 'react-dom/client'
import App from './App';
import { AuthProvider } from './context/AuthContext'; 

// Create a root element
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render your application
root.render(
    <AuthProvider>
        <App />
    </AuthProvider>
);
