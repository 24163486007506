import React, { useState, useEffect } from 'react';

const Consent = () => {
  const [consents, setConsents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedConsent, setSelectedConsent] = useState(null);
  const itemsPerPage = 4;
  const [formTypeFilter, setFormTypeFilter] = useState('all');
  const backendURL = process.env.REACT_APP_BackendURL;


  useEffect(() => {
    const fetchConsents = async () => {
      try {
        const response = await fetch(`${backendURL}/api/consents/getall`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch consents');
        }

        const data = await response.json();
        setConsents(data.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchConsents();
  }, []);

  const filteredConsents = consents.filter((consent) => {
    const searchTermLower = searchTerm.toLowerCase();
    const matchesSearch = (
      consent.email.toLowerCase().includes(searchTermLower) ||
      consent.phone.includes(searchTerm) ||
      consent.firstName.toLowerCase().includes(searchTermLower) ||
      consent.lastName.toLowerCase().includes(searchTermLower) ||
      `${consent.firstName} ${consent.lastName}`.toLowerCase().includes(searchTermLower)
    );
    const matchesFormType = formTypeFilter === 'all' || consent.formType === formTypeFilter;
    return matchesSearch && matchesFormType;
  });

  const totalPages = Math.ceil(filteredConsents.length / itemsPerPage);
  const indexOfLastConsent = page * itemsPerPage;
  const indexOfFirstConsent = indexOfLastConsent - itemsPerPage;
  const currentConsents = filteredConsents.slice(indexOfFirstConsent, indexOfLastConsent);

  const openModal = (consent) => {
    setSelectedConsent(consent);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedConsent(null);
    setIsModalOpen(false);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <div className="text-center">
        <div className='p-4 pt-5'>
          <div className="card shadow">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Consent Forms</h5>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-4">
                  <input 
                    className="form-control me-2" 
                    type="text" 
                    aria-label="Search"
                    placeholder="Search by name, email or phone..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>

              <table className="table">
                <thead className="table-dark">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Date</th>
                    <th 
                      className="dropdown-toggle" 
                      data-bs-toggle="dropdown" 
                      role="button"
                      aria-expanded="false"
                      style={{cursor: 'pointer'}}
                    >
                      Form Type
                      <ul className="dropdown-menu">
                        <li>
                          <button 
                            className="dropdown-item" 
                            onClick={() => setFormTypeFilter('all')}
                          >
                            All Forms
                          </button>
                        </li>
                        <li>
                          <button 
                            className="dropdown-item" 
                            onClick={() => setFormTypeFilter('opt-in')}
                          >
                            Opt-in
                          </button>
                        </li>
                        <li>
                          <button 
                            className="dropdown-item" 
                            onClick={() => setFormTypeFilter('opt-out')}
                          >
                            Opt-out
                          </button>
                        </li>
                      </ul>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentConsents.map((consent, index) => (
                    <tr key={consent._id} onClick={() => openModal(consent)} style={{ cursor: 'pointer' }}>
                      <td>{indexOfFirstConsent + index + 1}</td>
                      <td>{consent.firstName} {consent.lastName}</td>
                      <td>{consent.email}</td>
                      <td>{consent.phone}</td>
                      <td>{new Date(consent.createdAt).toLocaleDateString()}</td>
                      <td>{consent.formType}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-start">
                  <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                    <button
                      className="page-link"
                      onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                      disabled={page === 1}
                    >
                      Previous
                    </button>
                  </li>
                  <li className="page-item disabled">
                    Page {page} of {totalPages}
                  </li>
                  <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                    <button
                      className="page-link"
                      onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
                      disabled={page === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal show d-block" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Consent Details</h5>
                <button type="button" className="btn-close" onClick={closeModal}></button>
              </div>
              <div className="modal-body">
                {selectedConsent && (
                  <div>
                    <p style={{ display: 'flex', gap: '20px' }}>
                      <strong>Name:</strong>{selectedConsent.firstName} {selectedConsent.lastName}
                      <strong>Email:</strong>{selectedConsent.email}
                    </p>
                    <p><strong>Phone:</strong> {selectedConsent.phone}</p>
                    <p><strong>Message:</strong> {selectedConsent.message}</p>
                    <p><strong>Form Type:</strong> {selectedConsent.formType}</p>
                    <p><strong>Consent Given:</strong> {selectedConsent.consent ? 'Yes' : 'No'}</p>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Consent; 