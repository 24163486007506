import React, { useState } from 'react';

const Setting = () => {

  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const backendURL = process.env.REACT_APP_BackendURL; 

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdate({ firstName, email, password });
  };

  const handleUpdate = async (updatedDetails) => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${backendURL}/api/auth/update`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedDetails),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Update successful:', result);
      } else {
        console.error('Failed to update user:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };
  return (
    <div>
      <div className="text-center">
        <div className='p-4 pt-5'>
          <div class="card shadow">
            <div class="card-header d-flex justify-content-between align-items-center">
              <h5 class="mb-0">Profile</h5>
            </div>
            <div class="card-body">
              <form onSubmit={handleSubmit} class="row gx-3 gy-2 align-items-center">
                <div class="col-sm-4">
                  <label class="visually-hidden" for="specificSizeInputName">First Name:</label>
                  <input type="text" class="form-control" placeholder='First Name' value={firstName}
                    onChange={(e) => setFirstName(e.target.value)} />
                </div>
                <div class="col-sm-4">
                  <label class="visually-hidden" for="specificSizeInputGroupUsername">Email:</label>
                  {/* <div class="input-group"> */}
                    <input class="form-control" type="email" placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)} />
                  {/* </div> */}
                </div>
                <div class="col-sm-4">
                  <label class="visually-hidden" for="specificSizeInputGroupUsername">Password</label>
                  {/* <div class="input-group"> */}
                    {/* <div class="input-group-text">@</div> */}
                    <input class="form-control" type="password" placeholder='Password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)} />
                  {/* </div> */}
                </div>


                <div class="col-auto">
                  <button type="submit" class="btn btn-primary">Update</button>
                </div>
              </form>

            </div>
          </div>
        </div>



      </div>
    </div>
  )
}

export default Setting;
