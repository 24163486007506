import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Signup.css';

const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const backendURL = process.env.REACT_APP_BackendURL; 

  
  const navigate = useNavigate(); 

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!agreeToTerms) {
      alert('You must agree to the Terms of Service and Privacy Policy.');
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(`${backendURL}/api/auth/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ firstName, email, password }),
      });

      const result = await response.json();
      setLoading(false);

      if (response.ok) {
        alert('Account created successfully!');
        navigate('/signin'); 
      } else {
        alert(result.message || 'Signup failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during signup:', error);
      alert('An error occurred. Please try again later.');
      setLoading(false);
    }
  };

  const handleSignInClick = () => {
    navigate('/'); 
  };

  return (
    <div className="signup-container">
      <div className="signup-header">
        <div className="logo">
          <span className="logo-text">Medivo</span>
        </div>
        <button className="sign-in-button" onClick={handleSignInClick}>
          SIGN IN
        </button>
      </div>
      <div className="signup-form-container">
        <h1>Medivo</h1>
        <form onSubmit={handleSignup}>
          <div className="input-group">
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="USER NAME"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="EMAIL ADDRESS"
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="PASSWORD"
              required
            />
          </div>
          <div className="checkbox-group">
            <input
              type="checkbox"
              id="agreeTerms"
              checked={agreeToTerms}
              onChange={(e) => setAgreeToTerms(e.target.checked)}
            />
            <label htmlFor="agreeTerms">
              I agree to the Terms of Service and Privacy Policy
            </label>
          </div>
          <button type="submit" className="create-account-button" disabled={loading}>
            {loading ? 'Creating...' : 'CREATE AN ACCOUNT'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signup;
