import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import { HelmetProvider } from 'react-helmet-async'; 
//import Sidebar from '../components/SideBar'; // Import Sidebar
//import TopNav from '../components/TopNav';   // Import TopNav
import './BlogDetail.css';

const BlogDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const backendURL = process.env.REACT_APP_BackendURL; 


  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`${backendURL}/api/blogs/blogs/${id}`);
        if (!response.ok) throw new Error('Failed to fetch blog');
        const data = await response.json();
        setBlog(data);
        console.log(data);

        const relatedResponse = await fetch(`${backendURL}/api/blogs/blogs/related/${id}`);
        if (!relatedResponse.ok) throw new Error('Failed to fetch related blogs');
        const relatedData = await relatedResponse.json();
        setRelatedBlogs(relatedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchBlog();
  }, [id]);

  const handleDelete = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this blog?');
    if (confirmDelete) {
      try {
        const response = await fetch(`${backendURL}/api/blogs/blogs/${id}`, {
          method: 'DELETE',
        });
        if (!response.ok) throw new Error('Failed to delete blog');
        alert('Blog deleted successfully');
        navigate('/bloglist');
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const handleRelatedBlogClick = (relatedId) => {
    navigate(`/blogs/${relatedId}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const truncateSummary = (summary) => {
    const lines = summary.split('\n');
    return lines.slice(0, 3).join(' ');
  };

  const parseTags = (tagsArray) => {
    if (!Array.isArray(tagsArray) || tagsArray.length === 0) {
      return ['Invalid tags'];
    }
    return tagsArray.map((tag) => tag.replace(/^\[|\]$/g, '').replace(/['"]/g, ''));
  };

  const formattedPublicationDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(new Date(blog.publicationDate));

  const formattedUpdateDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(new Date(blog.updatedAt));

  const isArabic = (text) => /[\u0600-\u06FF]/.test(text);

  return (
    <HelmetProvider>
      
          <div className={`blog-post ${isArabic(blog.title) ? 'rtl' : ''}`}>
            {blog && (
              <Helmet>
                <title>{blog.metaTitle || 'Default Title'} - Your Blog Site</title>
                <meta name="description" content={blog.metaDescription || ''} />
                <meta name="keywords" content={parseTags(blog.metaKeywords).join(', ')} />
                <meta name="tags" content={parseTags(blog.metaTags).join(', ')} />
                <link rel="canonical" href={`http://localhost:3000/blogs/${id}`} />
              </Helmet>
            )}

            <div className="main-content">
              <button onClick={handleDelete} className="delete-button">
                Delete Blog
              </button>
              <button onClick={() => navigate(`/update/${id}`)} className="update-button">
                Update Blog
              </button>
              <h1 className="title">{parse(blog.title)}</h1>
              <div className="image-container">
                <img
                  src={blog.headerImage}
                  alt="Blog header"
                  className="header-image"
                />
              </div>
              <p className="summary">{blog.summary}</p>
              <div className="content">{parse(blog.fullContent)}</div>
              <p className="author">
                <strong>Author:</strong> {blog.authorName}
              </p>
              <p className="publication-date">
                <strong>Published on:</strong> {formattedPublicationDate}
              </p>
              <p className="update-date">
                <strong>Last updated:</strong> {formattedUpdateDate}
              </p>
              <div className="tags">
                <strong>Tags:</strong> {parseTags(blog.tags).join(', ')}
              </div>
            </div>

            <div className="related-articles">
              <h2>Related Articles</h2>
              <div className="related-blogs-container">
                {relatedBlogs.length > 0 ? (
                  relatedBlogs.map((relatedBlog) => (
                    <div
                      className={`related-blog-card ${isArabic(relatedBlog.title) ? 'rtl' : ''}`}
                      key={relatedBlog._id}
                      onClick={() => handleRelatedBlogClick(relatedBlog._id)}
                    >
                      { relatedBlog.headerImage}
                      <div className="related-blog-image">
                        <img
                          src={relatedBlog.headerImage}
                          alt={relatedBlog.title}
                        />
                      </div>
                      <div className="related-blog-content">
                        <h3 className="related-blog-title">{parse(relatedBlog.title)}</h3>
                        <p className="related-blog-summary">
                          {truncateSummary(relatedBlog.summary)}
                        </p>
                        <a
                          href={`/blogs/${relatedBlog._id}`}
                          className="learn-more-link"
                          onClick={(e) => {
                            e.preventDefault();
                            handleRelatedBlogClick(relatedBlog._id);
                          }}
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No related articles found.</p>
                )}
              </div>
            </div>
          </div>
       
    </HelmetProvider>
  );
};

export default BlogDetails;
