import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import parse from 'html-react-parser';
import '../components/Layout.css';


const Dashboard = () => {
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limit] = useState(4);
  const [searchQuery, setSearchQuery] = useState('');
  const [language, setLanguage] = useState('english');

  const navigate = useNavigate();
  const backendURL = process.env.REACT_APP_BackendURL; 


  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          `${backendURL}/api/blogs/blogs?page=${page}&limit=${limit}&search=${searchQuery}&language=${language}`
        );

        if (response.ok) {
          const result = await response.json();
          setBlogs(result.blogs);
          setTotalPages(result.totalPages);
        } else {
          console.error('Failed to fetch blogs:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, [page, limit, searchQuery, language]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const handleTogglePublish = async (id, currentStatus) => {
    try {
      const response = await fetch(`${backendURL}/api/blogs/blogs/${id}/publish`, {
        method: 'PATCH',
      });
  
      if (response.ok) {
        const updatedBlog = await response.json();
        setBlogs((prevBlogs) =>
          prevBlogs.map((blog) =>
            blog._id === id ? { ...blog, publish: !currentStatus } : blog
          )
        );
      } else {
        console.error('Failed to toggle publish status:', response.statusText);
      }
    } catch (error) {
      console.error('Error toggling publish status:', error);
    }
  };

  const handleUpdate = async (updatedDetails) => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${backendURL}/api/auth/update`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedDetails),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Update successful:', result);
        // setData(result);
        // setIsModalOpen(false); // Close modal after update
      } else {
        console.error('Failed to update user:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleAddBlog = () => {
    navigate('/add-blog');
  };
  // const handleViewBlog = () => {
  //   navigate('/bloglist');
  // };


  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this blog?');
    if (confirmDelete) {
      try {
        const response = await fetch(`${backendURL}/api/blogs/blogs/${id}`, {
          method: 'DELETE',
        });
        if (!response.ok) throw new Error('Failed to delete blog');
        alert('Blog deleted successfully');
        navigate('/bloglist'); // Redirect after deletion
      } catch (err) {
        console.error('Error deleting blog:', err.message); // Use console for now
      }
    }
  };

  return (
    <div>
      {/* <div className="wrapper">
        <SideBar />

        <div className="main">
          <TopNav /> */}
          <div className="text-center">
            <div className='p-4 pt-5'>
              <div class="card shadow">
                <div class="card-header d-flex justify-content-between align-items-center">
                  <h5 class="mb-0">Blogs</h5>
                  <a href="# "onClick={handleAddBlog} class="btn btn-primary">Add Blog</a>
                </div>
                <div class="card-body">
                  <div class="row mb-3">
                    <div class="col-4">
                      <input class="form-control me-2" type="text" aria-label="Search"
                        placeholder="Search something..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </div>
                    <div className="col-4">
                  <select 
                    className="form-select" 
                    value={language} 
                    onChange={(e) => setLanguage(e.target.value)}
                  >
                    <option value="english">English</option>
                    <option value="arabic">Arabic</option>
                  </select>
                </div>
                    {/* <div class="col-auto">
            <button class="btn btn-outline-success" type="submit">Search</button>
        </div> */}
                  </div>

                  <table class="table">

                    <thead class="table-dark">
                      <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {blogs.length > 0 ? (
                        blogs.map((blog, index) => (
                          <tr key={blog._id}>
                            <td>{index + 1 + (page - 1) * limit}</td>
                            <td className='stye-data'>{parse(blog.title)}</td>
                            <td>{new Date(blog.createdAt).toLocaleDateString()}</td>
                            <td>
                              <button
                                onClick={() => navigate(`/blogs/${blog._id}`)}
                                className="view-button"
                              >
                                View
                              </button>
                              <button
                                onClick={() => navigate(`/update/${blog._id}`)}
                                className="view-button"
                              >
                                Update
                              </button>
                              
                              {/* <button onClick={() => handleDelete(blog._id)} className="view-button">
                                Delete

                              </button>
                            {/* 
      <button
        onClick={() => handleTogglePublish(blog._id)}
        className={`view-button ${blog.publish ? "btn-danger" : "btn-success"}`}
      >
        {blog.publish ? "Unpublish" : "Publish"}
      </button>

      */}                              

                  




                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4">No blogs found</td>
                        </tr>
                      )}
                    </tbody>

                  </table>
                  <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-start">
                    <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                    <button
                    className="page-link"
                    onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                    disabled={page === 1}
                    >
                    Previous
                    </button>
                    </li>
                      Page {page} of {totalPages}
                      {/* <li class="page-item"><a class="page-link" href="#">1</a></li>
    <li class="page-item"><a class="page-link" href="#">2</a></li>
    <li class="page-item"><a class="page-link" href="#">3</a></li> */}
                      <li class="page-item">
                        <button class="page-link"
                          onClick={() => setPage((prev) => (prev < totalPages ? prev + 1 : prev))}
                          disabled={page === totalPages}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            {/* <button onClick={() => setIsModalOpen(true)} className="update-button">
                Update Details
              </button> */}

            {/* <div className="dashboard-container">
              <h1>Dashboard</h1>
              {data ? <p>{data.message}</p> : <p>Welcome Admin</p>}

              

              <button onClick={handleLogout} className="logout-button">
                Log out
              </button>

              <button onClick={handleAddBlog} className="add-blog-button">
                Add Blog
              </button>

              <button onClick={handleViewBlog} className="view-blog-button">
                View Blog
              </button>


              <div className="blogs-table-container">
                <h2>All Blogs</h2>
                <div className="filter-buttons">
                  <button onClick={() => setLanguage('English')}>English</button>
                  <button onClick={() => setLanguage('Arabic')}>Arabic</button>
                </div>
                <div className="search-bar-container">
                  <img src="/images/search.png" alt="search-icon" />
                  <input
                    type="text"
                    placeholder="Search something..."
                    className="search-input"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>


                <div className="pagination">
                  <button
                    onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                    disabled={page === 1}
                  >
                    Previous
                  </button>
                  <span>

                  </span>
                  <button
                    onClick={() => setPage((prev) => (prev < totalPages ? prev + 1 : prev))}
                    disabled={page === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>

              
            </div> */}
          </div>
          
        </div>


  );
};

export default Dashboard;
