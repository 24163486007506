import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import './AddBlog.css';
import Editor from '../Editor'; // Import your custom Editor component
import ReactQuill from 'react-quill'; // Import React Quill
import 'react-quill/dist/quill.snow.css'; // Import styles for React Quill
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'; 

const AddBlog = () => {

    const [title, setTitle] = useState('');
    const [publicationDate, setPublicationDate] = useState('');
    const [summary, setSummary] = useState('');
    const [fullContent, setFullContent] = useState(''); // This will be set by the Editor
    const [headerImage, setHeaderImage] = useState(null);
    const [tags, setTags] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');
    const [metaTags, setMetaTags] = useState('');
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    const navigate = useNavigate();
    const [isImageValid, setIsImageValid] = useState(false);
    const backendURL = process.env.REACT_APP_BackendURL; 

    const handleHeaderImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            const img = new Image();

            reader.onload = (event) => {
                img.onload = () => {
                    if (img.width === 1270 && img.height === 400) {
                        setHeaderImage(file);
                        setIsImageValid(true);
                        toast.success('Image dimensions are valid!', { position: "top-right" });
                    } else {
                        setIsImageValid(false);
                        toast.error('Header image dimensions must be 1270 x 400', { position: "top-right" });
                    }
                };
                img.src = event.target.result;
            };

            reader.readAsDataURL(file);
        }
    };


    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if header image is provided
        if (!headerImage) {
            toast.error('Header image is required!', { position: "top-right" });
            return;
        }
    
        const formData = new FormData();
        formData.append('title', title);
        formData.append('publicationDate', publicationDate);
        formData.append('summary', summary);
        formData.append('fullContent', fullContent);
        formData.append('metaTitle', metaTitle);
        formData.append('metaDescription', metaDescription);
        formData.append('metaKeywords', metaKeywords);
        formData.append('metaTags', metaTags);
    
        formData.append('tags', tags);
    
        formData.append('headerImage', headerImage);  // Ensure headerImage is added
        
        const selectedCategoryLabels = selectedCategories.map(category => category.label);
        formData.append('categories', JSON.stringify(selectedCategoryLabels));
        try {
            const response = await fetch(`${backendURL}/api/blogs/add-blog`, {
                method: 'POST',
                body: formData,
            });
    
            if (response.ok) {
                toast.success('Blog added successfully!', { position: "top-right" });
                setTimeout(() => {
                    navigate(`/dashboard`);
                }, 3000); 
            } else {
                toast.error('Failed to add blog', { position: "top-right" });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred while adding the blog', { position: "top-right" });
        }
    };
    
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${backendURL}/api/getallcategories`);
                const data = await response.json();
                if (response.ok) {
                    setCategories(data.categories.map(category => ({
                        value: category._id, 
                        label: category.name 
                    })));
                } else {
                    console.error("Error fetching categories:", data.message);
                    toast.error("Error fetching categories");
                }
            } catch (error) {
                console.error("Error fetching categories:", error);
                toast.error("Error fetching categories");
            }
        };
        fetchCategories();
    }, []);

    return (
        <div>
            <div >
            <ToastContainer />
                <div className='p-4 pt-5'>
                    <div className="card shadow">
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <h5 class="mb-0">Create</h5>
                            <a href="# " class="btn btn-primary">All Blog</a>
                        </div>
                        <div className="add-blog-container p-4">
                            <div className="main-content">

                                <form onSubmit={handleSubmit} className="row g-3">
                                    <div className="col-md-6">
                                        <label for="inputEmail4" className="form-label">Title</label>
                                        <ReactQuill value={title} onChange={setTitle} theme="snow" />
                                        {/* <input type="email" className="form-control" id="inputEmail4" /> */}
                                    </div>
                                    <div className="col-md-6">
                                        <label for="inputPassword4" className="form-label">Publication Date</label>
                                        <input 
                                        value={publicationDate}
                                        onChange={(e) => setPublicationDate(e.target.value)}
                                        type="date" className="form-control" id="inputPassword4" />
                                    
                                    </div>
                                    <div className="col-12">
                                        <label for="inputAddress" className="form-label">Summary</label>
                                        <textarea type="textarea" className="form-control" value={summary}
                                            onChange={(e) => setSummary(e.target.value)}
                                            required />
                                    </div>
                                    <div className="col-12">
                                        <label for="inputAddress2" className="form-label">Content: </label>
                                        <Editor setEditorContent={setFullContent} />
                                    </div>
                                    <div className="col-12">
                            <label className="form-label">Categories</label>
                            <Select
                                isMulti
                                options={categories} 
                                value={selectedCategories}
                                onChange={setSelectedCategories} 
                                placeholder="Select Category"

                            />
                        </div>

                                    <div className="col-12">
                                        <label for="inputAddress2" className="form-label">Tags: </label>
                                        <input type="text"
                                            className="form-control"
                                            value={tags}
                                            onChange={(e) => setTags(e.target.value)} />
                                    </div>
                                    <div className="col-12">
                                        <label for="formFile" className="form-label">Header Image : </label>
                                        <input className="form-control" type="file"onChange={handleHeaderImageChange} />
                                    </div>
                                    <hr></hr>
                                    <div className="col-12">
                                        <label for="inputAddress2" className="form-label">Meta Title </label>
                                        <input type="text"
                                            className="form-control"
                                            value={metaTitle}
                                            onChange={(e) => setMetaTitle(e.target.value)}
                                            required />
                                    </div>

                                    <div className="col-12">
                                        <label for="inputAddress" className="form-label">Meta Description :</label>
                                        <textarea type="textarea" className="form-control" value={metaDescription}
                                            onChange={(e) => setMetaDescription(e.target.value)}
                                            required />
                                    </div>

                                    <div className="col-12">
                                        <label for="inputAddress2" className="form-label">Meta Keywords (comma-separated): </label>
                                        <input type="text"
                                            className="form-control"
                                            value={metaKeywords}
                                            onChange={(e) => setMetaKeywords(e.target.value)} />
                                    </div>

                                    <div className="col-12">
                                        <label for="inputAddress2" className="form-label">Meta Tags (comma-separated): </label>
                                        <input type="text"
                                            className="form-control"
                                            value={metaTags}
                                            onChange={(e) => setMetaTags(e.target.value)} />
                                    </div>

                                    <div className="col-12">
                                    {/* <button onClick={() => navigate('/bloglist')}>View Blogs</button> */}
                                        <button type="submit" className="btn btn-primary">Add</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        


 {/* <div className="add-blog-container">
                            <div className="main-content">
                                <div className="form-container">
                                    <form onSubmit={handleSubmit}>
                                        <div className="field">
                                            <label className="label">Title:</label>
                                            <ReactQuill value={title} onChange={setTitle} theme="snow" />
                                        </div>
                                        <div className="field">
                                            <label className="label">Author Name:</label>
                                            <input
                                                type="text"
                                                value={authorName}
                                                onChange={(e) => setAuthorName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="field">
                                            <label className="label">Publication Date:</label>
                                            <input
                                                type="date"
                                                value={publicationDate}
                                                onChange={(e) => setPublicationDate(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="field">
                                            <label className="label">Summary:</label>
                                            <textarea
                                                value={summary}
                                                onChange={(e) => setSummary(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="field">
                                            <label className="label">Full Content:</label>
                                            <Editor setEditorContent={setFullContent} />
                                        </div>
                                        <div className="field">
                                            <label className="label">Header Image:</label>
                                            <input type="file" onChange={(e) => setHeaderImage(e.target.files[0])} />
                                        </div>
                                        <div className="field">
                                            <label className="label">Tags (comma-separated):</label>
                                            <input
                                                type="text"
                                                value={tags}
                                                onChange={(e) => setTags(e.target.value)}
                                            />
                                        </div>
                                        <div className="field">
                                            <label className="label">Meta Title:</label>
                                            <input
                                                type="text"
                                                value={metaTitle}
                                                onChange={(e) => setMetaTitle(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="field">
                                            <label className="label">Meta Description:</label>
                                            <textarea
                                                value={metaDescription}
                                                onChange={(e) => setMetaDescription(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="field">
                                            <label className="label">Meta Keywords (comma-separated):</label>
                                            <input
                                                type="text"
                                                value={metaKeywords}
                                                onChange={(e) => setMetaKeywords(e.target.value)}
                                            />
                                        </div>
                                        <div className="field">
                                            <label className="label">Meta Tags (comma-separated):</label>
                                            <input
                                                type="text"
                                                value={metaTags}
                                                onChange={(e) => setMetaTags(e.target.value)}
                                            />
                                        </div>
                                        <button type="submit">Add Blog</button>
                                    </form>
                                    <button onClick={() => navigate('/bloglist')}>View Blogs</button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddBlog;
