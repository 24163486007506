import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard'; 
import DashboardLayout from './components/DashboardLayout';
import AddBlog from './pages/Blogs/AddBlog';
import Bloglist from './pages/Bloglist';
import Leads from './pages/Leads/Lead';
import BlogDetails from './pages/BlogDetail';
import Profile from './pages/Profile/Setting';
import UpdateBlog from './pages/UpdateBlog';
import ProtectedRoute from './components/ProtectedRoute';
// import ConsentPage from './pages/ConsentPage';
import Category from './pages/Category/Category'
import Consent from './pages/consent/Consent';

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <Dashboard />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/leads"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <Leads />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-blog"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <AddBlog />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <Profile />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/bloglist"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <Bloglist />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/blogs/:id"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <BlogDetails />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/update/:id"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <UpdateBlog />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/contact"
          element={
            <ProtectedRoute>
                <ConsentPage />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/consent"
          element={
            <ProtectedRoute>
               <DashboardLayout>
              <Consent />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />



<Route
          path="/category"
          element={
            <ProtectedRoute>
              <DashboardLayout>
                <Category />
              </DashboardLayout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
