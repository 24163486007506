import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css'; 
import Editor from './Editor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'; 


const UpdateBlog = () => {
  const { id } = useParams(); 
  const navigate = useNavigate(); 

  const [title, setTitle] = useState('');
  const [publicationDate, setPublicationDate] = useState('');
  const [summary, setSummary] = useState('');
  const [fullContent, setFullContent] = useState(''); 
  const [headerImage, setHeaderImage] = useState(null);
  const [tags, setTags] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [metaTags, setMetaTags] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isImageValid, setIsImageValid] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const backendURL = process.env.REACT_APP_BackendURL; 

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`${backendURL}/api/blogs/blogs/${id}`);
        if (!response.ok) throw new Error('Failed to fetch blog details');
        const data = await response.json();

        setTitle(data.title);
        setPublicationDate(data.publicationDate);
        setSummary(data.summary);
        setFullContent(data.fullContent || '');
       // const parsedTags = JSON.parse(data.tags).join(', ');
        setTags(data.tags);

        

        setMetaTitle(data.metaTitle);
        setMetaDescription(data.metaDescription);
        //const parsedMetaKeywords = JSON.parse(data.metaKeywords).join(', ');
        setMetaKeywords(data.metaKeywords);
       // const parsedMetaTags = JSON.parse(data.metaTags).join(', ');
        setMetaTags(data.metaTags);
        setSelectedCategories(data.categories || []);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
          const response = await fetch(`${backendURL}/api/getallcategories`);
          const data = await response.json();
          if (response.ok) {
              setCategories(data.categories.map(category => ({
                  value: category.name, // Use name since categories are strings
                  label: category.name,
              })));
          } else {
              console.error("Error fetching categories:", data.message);
              toast.error("Error fetching categories");
          }
      } catch (error) {
          console.error("Error fetching categories:", error);
          toast.error("Error fetching categories");
      }
  };
  fetchCategories();


    fetchBlog();
  }, [id]);

  const handleHeaderImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
        const reader = new FileReader();
        const img = new Image();

        reader.onload = (event) => {
            img.onload = () => {
                if (img.width === 1270 && img.height === 400) {
                    setHeaderImage(file);
                    setIsImageValid(true);
                    toast.success('Image dimensions are valid!', { position: "top-right" });
                } else {
                    setIsImageValid(false);
                    toast.error('Header image dimensions must be 1270 x 400', { position: "top-right" });
                }
            };
            img.src = event.target.result;
        };

        reader.readAsDataURL(file);
    }
};

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('publicationDate', publicationDate);
    formData.append('summary', summary);
    formData.append('fullContent', fullContent);
    if (headerImage) formData.append('headerImage', headerImage);

    formData.append('tags', tags);
    formData.append('metaTitle', metaTitle);
    formData.append('metaDescription', metaDescription);
    formData.append('metaKeywords', metaKeywords);
    formData.append('metaTags', metaTags);
    formData.append('categories', JSON.stringify(selectedCategories));

    try {
      const response = await fetch(`${backendURL}/api/blogs/blogs/${id}`, {
        method: 'PUT',
        body: formData,
      });

      if (response.ok) {
        toast.success('Blog Updated successfully!', { position: "top-right" });
        setTimeout(() => {
            navigate(`/dashboard`);
        }, 3000); 
    } else {
        toast.error('Failed to add blog', { position: "top-right" });
    }
} catch (error) {
    console.error('Error:', error);
    toast.error('An error occurred while adding the blog', { position: "top-right" });
}
};


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
  
    <div className="add-blog-container">
        <ToastContainer />
      <div className="p-4 pt-5">
        <div className="card shadow">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Update Blog</h5>
            <a href="# " className="btn btn-primary">All Blogs</a>
          </div>
          <div className="add-blog-container p-4">
            <form onSubmit={handleUpdate} className="row g-3">
              <div className="col-md-6">
                <label className="form-label">Title</label>
                <ReactQuill value={title} onChange={setTitle} theme="snow" required />
              </div>

              <div className="col-md-6">
                <label className="form-label">Publication Date</label>
                <input 
                  type="date" 
                  className="form-control" 
                  value={publicationDate} 
                  onChange={(e) => setPublicationDate(e.target.value)} 
                  required 
                />
              </div>
              <div className="col-12">
                <label className="form-label">Summary</label>
                <textarea 
                  className="form-control" 
                  value={summary} 
                  onChange={(e) => setSummary(e.target.value)} 
                  required 
                />
              </div>
              <div className="col-12">
                <label className="form-label">Full Content</label>
                <Editor setEditorContent={setFullContent} initialContent={fullContent} />
              </div>
              <div className="col-12">
                <label className="form-label">Header Image</label>
                <input 
                  type="file" 
                  className="form-control" 
                  accept="image/*" 
                  onChange={handleHeaderImageChange}
                  placeholder="Select Category"

                />
              </div>

              <div className="col-12">
    <label className="form-label">Categories</label>
    <Select
        isMulti
        options={categories}
        value={selectedCategories.map(category => ({
            label: category,
            value: category
        }))} 
        onChange={(selected) => {
            setSelectedCategories(selected.map(category => category.value));
        }}
    />
</div>


              <div className="col-12">
                <label className="form-label">Tags (comma-separated)</label>
                <input 
                  type="text" 
                  className="form-control" 
                  value={tags} 
                  onChange={(e) => setTags(e.target.value)} 
                />
              </div>
              <div className="col-12">
                <label className="form-label">Meta Title</label>
                <input 
                  type="text" 
                  className="form-control" 
                  value={metaTitle} 
                  onChange={(e) => setMetaTitle(e.target.value)} 
                />
              </div>
              <div className="col-12">
                <label className="form-label">Meta Description</label>
                <textarea 
                  className="form-control" 
                  value={metaDescription} 
                  onChange={(e) => setMetaDescription(e.target.value)} 
                />
              </div>
              <div className="col-12">
                <label className="form-label">Meta Keywords (comma-separated)</label>
                <input 
                  type="text" 
                  className="form-control" 
                  value={metaKeywords} 
                  onChange={(e) => setMetaKeywords(e.target.value)} 
                />
              </div>
              <div className="col-12">
                <label className="form-label">Meta Tags (comma-separated)</label>
                <input 
                  type="text" 
                  className="form-control" 
                  value={metaTags} 
                  onChange={(e) => setMetaTags(e.target.value)} 
                />
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-primary">Update Blog</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateBlog;
