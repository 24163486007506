import React, { useEffect, useRef, useState } from 'react';
import 'quill/dist/quill.snow.css';
import Quill from 'quill';
import ImageResize from 'quill-resize-image'; // Import the resize module
import LinkModal from './LinkModal'; // Import your modal

Quill.register('modules/resizeImage', ImageResize);

const Editor = ({ setEditorContent, initialContent }) => {
  const editorRef = useRef(null); // Ref to avoid re-initializing Quill
  const [isModalVisible, setModalVisible] = useState(false);
  const [quill, setQuill] = useState(null); // Store Quill instance

  
  useEffect(() => {
    if (quill) return; // Prevent re-initialization if quill is already set

    const quillInstance = new Quill(editorRef.current, {
      modules: {
        toolbar: {
          container: '#toolbar-container',
          handlers: {
            link: () => setModalVisible(true),
            color: function (value) {
              if (value) this.quill.format('color', value);
            },
            background: function (value) {
              if (value) this.quill.format('background', value);
            },
          },
        },
        resizeImage: true, // Enable image resizing
      },
      placeholder: 'Compose an epic...',
      theme: 'snow',
    });

    // Initialize with initial content if provided
    if (initialContent) {
      quillInstance.clipboard.dangerouslyPasteHTML(initialContent);
    }

    quillInstance.on('text-change', () => {
      setEditorContent(quillInstance.root.innerHTML);
    });

    setQuill(quillInstance);
   
  }, [quill, initialContent, setEditorContent]);

  

  const handleLinkSubmit = (url, isNofollow) => {
    if (url && quill) {
      const range = quill.getSelection(); // Get the selected text range
  
      if (range) {
        quill.format('link', url); // Format the selected text as a link
        setTimeout(() => {
          const [linkElement] = quill.container.querySelectorAll(`a[href="${url}"]`);
          if (linkElement) {
            // Set or remove 'rel' attribute based on isNofollow
            if (isNofollow) {
              linkElement.setAttribute('rel', 'nofollow');
            } else {
              linkElement.removeAttribute('rel'); // Remove 'rel' attribute for dofollow links
            }
            linkElement.setAttribute('target', '_blank'); // Open in a new tab
          }
        }, 0);
      } else {
        const index = quill.getLength(); // If no text is selected, insert the link at the end
        quill.insertText(index, url, { link: url });
        setTimeout(() => {
          const [newLink] = quill.container.querySelectorAll(`a[href="${url}"]`);
          if (newLink) {
            if (isNofollow) {
              newLink.setAttribute('rel', 'nofollow');
            } else {
              newLink.removeAttribute('rel'); // Remove 'rel' for dofollow
            }
            newLink.setAttribute('target', '_blank');
          }
        }, 0);
      }
    }
  };
  

  return (
    <div>
      <div id="toolbar-container">
        {/* Toolbar */}
        <span className="ql-formats">
          <select className="ql-font"></select>
          <select className="ql-size"></select>
        </span>
        <span className="ql-formats">
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          <button className="ql-strike"></button>
        </span>
        <span className="ql-formats">
          <input
            type="color"
            className="ql-color-picker"
            onChange={(e) => quill && quill.format('color', e.target.value)}
          />
          <input
            type="color"
            className="ql-background-picker"
            onChange={(e) => quill && quill.format('background', e.target.value)}
          />
        </span>
        <span className="ql-formats">
          <button className="ql-script" value="sub"></button>
          <button className="ql-script" value="super"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-header" value="1"></button>
          <button className="ql-header" value="2"></button>
          <button className="ql-blockquote"></button>
          <button className="ql-code-block"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
          <button className="ql-indent" value="-1"></button>
          <button className="ql-indent" value="+1"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-direction" value="rtl"></button>
          <select className="ql-align"></select>
        </span>
        <span className="ql-formats">
          <button className="ql-link"></button>
          <button className="ql-image"></button>
          <button className="ql-video"></button>
          <button className="ql-formula"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-clean"></button>
        </span>
      </div>
      <div id="editor" ref={editorRef}></div>
      <LinkModal
        visible={isModalVisible}
        onClose={() => setModalVisible(false)}
        onSubmit={handleLinkSubmit}
      />
    </div>
  );
};

export default Editor;
